export default {
  "US": {
      name: "Freeport Forwarding",
      line1: "11320 State Rt 9 #124444",
      line2: null,
      city: "Champlain",
      region: "NY",
      country: "USA",
      postCode: "12919-5007",
      phone: "+15182988582",
  },
  "CA": {
      name: "Espaces des Arts",
      line1: "9 rue Sainte-Catherine Est",
      line2: "2e étage",
      city: "Montréal",
      region: "QC",
      country: "Canada",
      postCode: "H2X 1K3",
      phone: "+15145035703",
  },
  "DE": {
      name: "Quivo, c/o Logsta GmbH",
      line1: "Kopernikusstraße 16",
      line2: null,
      city: "Kösching",
      region: null,
      country: "Germany",
      postCode: "85092",
      phone: "017683141530",
  },
}