import { useEffect, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import TextField from '@mui/material/TextField';
import OrderTable from '../ProdOrderPage/OrderTable';
import { createOrder } from '../../firebase';
import { regionFromCountryCode } from '../../utils/regionFromCountryCode';
import { Box } from '@mui/material';
import addresses from '../../warehouse-addresses';


// Utility function
function findOrCreateShipment(order, warehouse, addresses, reference) {
    let shipment = order.shipments.find(shipment => shipment.warehouse === warehouse);
    if (!shipment) {
        if (!addresses[warehouse]) {
            throw new Error(`Address not found for warehouse: ${warehouse}`);
        }
        shipment = {
            address: addresses[warehouse],
            items: [],
            lineItems: [],
            warehouse,
            payments: [],
            reference: `${reference}-${warehouse}`,
            tracking: null,
        };
        order.shipments.push(shipment);
    }
    return shipment;
}

function findOrCreateItem(currentShipment, sku, itemDetails) {
    let item = currentShipment.items.find(item => item.sku === sku);
    if (!item) {
        item = {
            barcode: itemDetails.barcode || null,
            name: itemDetails.name,
            size: itemDetails.size,
            sku: sku,
            qty: 0,
        };
        currentShipment.items.push(item); // Add the new item to the shipment
    }
    return item;
}

function CreateOrderMenuItem({lineItems, closeMenu}) {
    const [showModal, setShowModal] = useState(false);
    const [reference, setReference] = useState('');
    const [order, setOrder] = useState();
    const [creating, setCreating] = useState(false);

    useEffect(() => {
        // Get order reference
        const today = new Date();
        const lastMonthDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthStr = new Intl.DateTimeFormat('en-CA', {month: '2-digit'}).format(lastMonthDate).toUpperCase();
        setReference(`${lastMonthDate.getFullYear().toString().substr(-2)}${lastMonthStr}S`);
    }, []);

    useEffect(() => {
        /* 
                Format order data as will be uploaded to firebase.
                {
                    createdAt: timestamp,
                    payments: [{
                        type: “merchandise”,
                        amount: number,
                        currency: string,
                    }]
                        reference: string,
                        status: string,
                    shipments: [{
                        address: {
                            line1: string,
                            line2: string,
                            city: string,
                            region: string,
                            country: string,
                            postalCode: string,
                        },
                        items: [{
                            name: string,
                            sku: string,
                            qty: number,
                            size: string,
                        }],
                        [stock orders only] lineItems: [{
                            
                        }],
                        name: string,
                        statuses: [{
                            date: string,
                            details: {
                                contact: string,
                                error: null,
                                status: "success"|"failed"
                                type: "email"|"phone"
                            },
                            eventName: string
                            failed: number
                            successful: number

                        }],
                        payments: [{
                            type: “merchandise”, “shipping”, “duties”
                            amount: number,
                            currency: string,
                        }]
                        reference,
                        tracking,
                        }],
                        type: “production” | “stock”,
                    }
            */

        // Consolidate quantities and shipments
        const order = {
            createdAt: new Date().toISOString(),
            payments: [],
            reference,
            status: "new",
            shipments: [],
            type: "stock"
        };

        for (let { barcode, docId, name, reservedQty, shippingCountryCode, sku } of lineItems) {
            // try to extract size
            const size = sku.match(/-([SML]|XS|XL|\d{2})$/i)?.[1] || null;
            name = name || sku.match(/(.+)(?:-(?:[SML]|XS|XL|\d{2}))$/i)?.[1] || "";
            const warehouse = regionFromCountryCode(shippingCountryCode, true).toUpperCase();

            const currentShipment = findOrCreateShipment(order, warehouse, addresses, reference);
        
            const itemDetails = { barcode: barcode || null, name, size };
            const currentItem = findOrCreateItem(currentShipment, sku, itemDetails);

            currentItem.qty += reservedQty;
            currentShipment.lineItems.push({docId, qty: reservedQty, warehouse});
        }
        setOrder(order);
    }, [lineItems, reference])

    const handleOpenModal = () => {
        setCreating(false);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleCreateOrder = async () => {
        setCreating(true);
        try {
            await createOrder(reference, order, 'stock');
        } catch (error) {
            console.error(error);
        }
        handleCloseModal();
    }

    const handleClickMenuItem = () => {
        !showModal && handleOpenModal();
        closeMenu();
    }

    const renderModal = (
        <Dialog
            open={showModal}
            onClose={handleCloseModal}
            maxWidth={'full'}
            sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }} // Ensures it's above tooltips
        >
        {!creating ? 
            <>
                <DialogTitle>Commande Stock</DialogTitle>
                <DialogContent>
                <p>Une commande sera créée à partir des articles séparées.</p>
                <Grid container spacing={2} sx={{mt: '4px'}}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            required
                            id='reference'
                            label='Reference'
                            defaultValue={reference}
                            placeholder="yyyyMMS"
                            onChange={(evt) => setReference(evt.target.value)}
                        />
                    </Grid>
                </Grid>
                <Box sx={{paddingTop:"30px"}}>
                    <OrderTable order={order} />
                </Box>
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Annuler</Button><Button onClick={handleCreateOrder}>Créer</Button>
                </DialogActions>
            </> :
            <>
                <DialogTitle>Veuillez Patienter</DialogTitle>
                <DialogContent><p>La création de la commande peut prendre plusieurs secondes. Ne fermez pas cette page.</p></DialogContent>
            </>}
        </Dialog>
    )

    return (
        <MenuItem onClick={handleClickMenuItem} disabled={!lineItems.length}>
            <ListItemIcon><ShoppingCartCheckoutIcon /></ListItemIcon>
            <ListItemText>Créer une commande (articles séparées)</ListItemText>
            {renderModal}
        </MenuItem>
    )
}

export default CreateOrderMenuItem;