import { useEffect, useState } from 'react';
import { deleteOrder, editOrder, sendKlaviyoEvent } from '../../firebase';
import useAuth from '../../Hooks/useAuth';
import OrderTable from '../ProdOrderPage/OrderTable';
import OldDownloadMenu from './OldDownloadMenu';

import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import DataButton from '../DataButton';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Paper from '@mui/material/Paper';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EmailIcon from '@mui/icons-material/Email';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoadingButton from '../LoadingButton';
import { ReactComponent as DlhLogo } from './dhl-logo.svg';
import {ReactComponent as EmsLogo } from './ems-logo.svg';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ExpandableOrderCard = ({order}) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [tracking, setTracking] = useState({});
    const [shippingPaid, setShippingPaid] = useState({});
    const [merchPaid, setMerchPaid] = useState();
    const [modal, setModal] = useState(null);
    const [deleting, setDeleting] = useState();
    const [shipmentOnTheWayDisabled, setShipmentOnTheWayDisabled] = useState(true);
    const { user } = useAuth();

    useEffect(() => {
        setTracking(order.tracking || {});
        setShippingPaid(order.shippingPaid || {});
        setMerchPaid(order.merchPaid);
        setShipmentOnTheWayDisabled(order.events?.shipmentOnTheWay);
    }, [order]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleEditModeToggle = () => {
        setEditMode(!editMode);
    }

    const handleModalClose = () => {
        setModal(null);
    }

    const handleCancelOrder = () => {
        setModal({
            prompt: (<>
                <p>Veuillez confirmer l'annulation de la commande {order.reference}.</p>
            </>),
            successCallback: () => {
                cancelOrder(order);
            },
        });
    }

    const cancelOrder = async (order) => {
        setDeleting(true);
        await deleteOrder(order);
        setDeleting(false);
    }

    const handleTrackingChange = async (store, trackingNumber) => {
        setEditMode(false);
        const newTracking = {
            ...tracking,
            [store]: trackingNumber || ""
        }
        setTracking(newTracking);
        const success = await editOrder(order.id, { tracking: newTracking });
        if (success) {
            console.info('Tracking edited');
        }
    }

    const handleShippingPaidChange = async (store, paid) => {
        const newPaid = parseInt((paid || 0)* 100);
        setEditMode(false);
        const newShippingPaid = {
            ...shippingPaid,
            [store]: newPaid
        }
        setShippingPaid(newShippingPaid);
        const success = await editOrder(order.id, { shippingPaid: newShippingPaid });
        if (success) {
            console.info('ShippingPaid edited');
        }
    }

    const handleMerchPaidChange = async (paid) => {
        const newPaid = parseInt((paid || 0) * 100);
        setEditMode(false);
        setMerchPaid(newPaid);
        const success = await editOrder(order.id, { merchPaid: newPaid });
        if (success) {
            console.info('MerchPaid edited');
        }
    }

    const handleSendEvent = async (eventName) => {
        switch (eventName) {
            case 'shipment-on-the-way':
                setShipmentOnTheWayDisabled(true);
                break;
        }
        sendKlaviyoEvent({eventName, orderId: order.id});
    }

    const totalPerStore = {};
    for (const item of order.items) {
        for (const store of order.stores) {
            if (totalPerStore[store] === undefined) {
                totalPerStore[store] = 0;
            }
            totalPerStore[store] += item.qty[store] || 0;
        }
    }

    const getStatusChip = () => {
        switch (order.status) {
            case 'shipped':
                return <Chip label="ENVOYÉE" color='primary' />
            case 'paid':
                return <Chip label="PAYÉE" />
        }
    }

    const getTrackingInfo = (trackingNumber, region) => {
        if (!trackingNumber) return;
        const firstNumber = trackingNumber.split(',')[0];
        if (firstNumber.match(/^[A-Z]{2}\d{9}[A-Z]{2}$/)) {
            // EMS
            let trackingUrl;
            switch(region) {
                case 'usa':
                case 'US':
                    trackingUrl=`https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=3&text28777=&tLabels=${trackingNumber}`;
                    break;
                case 'canada':
                case 'CA':
                    trackingUrl=`https://www.canadapost-postescanada.ca/track-reperage/en#/details/${trackingNumber}`;
                    break;
                case 'europe':
                case 'EU':
                    trackingUrl=`https://www.dhl.com/us-en/home/tracking/tracking-parcel.html?submit=1&tracking-id=${trackingNumber}`;
                    break;
                default:
                    trackingUrl=`https://www.aftership.com/track?t=${trackingNumber}`;
            }
            return {
                shipperIcon: <EmsLogo fill={theme.palette.primary.main} height={"9px"} width={"30px"} />,
                trackingUrl,
            }
        }
        if (firstNumber.match(/^\d{10}$/)) {
            // DHL
            return {
                shipperIcon: <DlhLogo fill={theme.palette.primary.main} height={"9px"} />,
                trackingUrl: `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackingNumber}`
            }
        }
    }

    const lastShipmentOnTheWayFails = order.eventList && order.eventList
        .filter(event => event.eventName === "shipment-on-the-way")
        .sort((a, b) => b.date - a.date)?.[0]?.failed;

        // undefined => there is no lastShipmentOnTheWay event
        // [] => there are no fails in the lastShipmentOnTheWay event

    const lastShipmentOnTheWaySentSuccessfully = lastShipmentOnTheWayFails && lastShipmentOnTheWayFails.length === 0;

    return (
        <>
        <Badge invisible={!(order.status === 'new')} badgeContent={' '} color="success">
        <Card key={order.id} sx={{width: "100%", filter: deleting && 'blur(5px)'}}>
            <CardHeader
                action={<Stack spacing={1} direction="row">
                        {getStatusChip()}
                        <IconButton aria-label="edit" onClick={handleEditModeToggle}>
                            {editMode ? <EditOffIcon /> : <EditIcon />}
                        </IconButton>
                        {order?.status === 'new' && <IconButton aria-label='cancel' color='error' onClick={handleCancelOrder}><DeleteForeverIcon /></IconButton>}
                    </Stack>}
                title={`${order.reference} - ${order.type.toUpperCase()}`}
                subheader={Intl.DateTimeFormat('fr-CA',{dateStyle: 'long'}).format(order.createdAt.toDate())}
            />
            <CardContent>
                <Stack direction={'column'} spacing={2} alignItems="center" >
                {merchPaid !== undefined && !editMode 
                ? <Chip icon={<ShoppingCartIcon />} size='small' label={`PAYÉ ${(merchPaid/100).toFixed(2)} USD`} color={'secondary'} />
                : user?.isAdmin && <DataButton
                    size='small'
                    startIcon={<AddIcon />}
                    dataDescription="Montant de la marchandise payé"
                    initialData={merchPaid}
                    onDataChange={(newMerchPaid) => handleMerchPaidChange(newMerchPaid)}
                    color={editMode ? 'error' : 'primary'}
                    variant={merchPaid ? 'contained' : 'outlined'}
                >
                PAIEMENT
                </DataButton>}
                <Stack direction={'row'} justifyContent={'center'} spacing={2}>
                    {order.stores.map(store => {
                        const trackingInfo = tracking[store] && getTrackingInfo(tracking[store], store);
                        return (
                            <Paper key={store} elevation={0} sx={{p:'20px'}}>
                                <Stack alignItems={'center'} spacing={2}>
                                    <Stack alignItems={'center'} spacing={1} direction='column'>
                                        <Typography lineHeight={'1.2rem'} fontSize={'1.2rem'}>{store.toUpperCase()}</Typography>
                                        {order.address && <>
                                            <Typography lineHeight={'0.8rem'} fontSize={'0.8rem'}>{order.address.name}</Typography>
                                        </>}
                                        <Typography lineHeight={'2.5rem'} fontSize={'2.5rem'}>{totalPerStore[store]}</Typography>
                                        <Typography lineHeight={'1rem'} fontSize={'1rem'}>PAIRES</Typography>
                                    </Stack>
                                    <Divider variant='middle' flexItem />
                                    <Stack alignItems={'center'} spacing={1} direction='column'>
                                    {tracking[store] && !editMode 
                                        ? <Button 
                                            size='small' 
                                            component='a' 
                                            startIcon={trackingInfo?.shipperIcon}
                                            href={trackingInfo?.trackingUrl}
                                            target={'_blank'}
                                        >
                                            {tracking[store]}
                                        </Button> 
                                        : <DataButton
                                            size='small'
                                            startIcon={<AddIcon />}
                                            dataDescription="Numéro de suivi"
                                            initialData={tracking[store]}
                                            onDataChange={(newTracking) => handleTrackingChange(store, newTracking)}
                                            color={editMode ? 'error' : 'primary'}
                                            variant={tracking[store] ? 'contained' : 'outlined'}
                                        >
                                            # DE SUIVI
                                        </DataButton>}
                                    {shippingPaid[store] !== undefined && !editMode 
                                        ? <Chip icon={<LocalShippingIcon />} size='small' label={`PAYÉ ${(shippingPaid[store]/100).toFixed(2)} USD`} color={'secondary'} /> 
                                        : user?.isAdmin && <DataButton
                                            size='small'
                                            startIcon={<AddIcon />}
                                            dataDescription="Montant du transport payé"
                                            initialData={(shippingPaid[store]/100).toFixed(2)}
                                            onDataChange={(newShippingPaid) => handleShippingPaidChange(store, newShippingPaid)}
                                            color={editMode ? 'error' : 'primary'}
                                            variant={shippingPaid[store] ? 'contained' : 'outlined'}
                                        >
                                            PAIEMENT
                                        </DataButton>}
                                    </Stack>
                                </Stack>
                            </Paper>
                        )
                    })}
                </Stack>
                </Stack>
            </CardContent>
            <CardActions disableSpacing>
                <Stack spacing={1} direction='row'>
                    <OldDownloadMenu order={order} />
                    {(user?.isAdmin || user?.isStaff) && order.type === 'stock' && 
                        <LoadingButton variant='contained' startIcon={lastShipmentOnTheWaySentSuccessfully ? <CheckIcon /> : <EmailIcon />} loading={false} disabled={lastShipmentOnTheWaySentSuccessfully} onClick={() => handleSendEvent("shipment-on-the-way")} aria-label="send shipment on the way email">
                            SHIPMENT ON THE WAY {!!(lastShipmentOnTheWayFails && lastShipmentOnTheWayFails.length) && `(RESEND ${lastShipmentOnTheWayFails.length} FAILED)`}
                        </LoadingButton>}
                </Stack>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <OrderTable order={order} />
                </CardContent>
            </Collapse>
        </Card> 
        </Badge>
        <Modal
            open={!!modal || false}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack direction={'column'} spacing={2}>
                    {modal?.prompt}
                    <Stack direction={'row'} justifyContent='flex-end' spacing={1}>
                        <Button onClick={handleModalClose} disabled={deleting}>Annuler</Button>
                        <Button onClick={modal?.successCallback} disabled={deleting} variant='contained'>Confirmer</Button>
                    </Stack>

                </Stack>
            </Box>
        </Modal>
        </>
    )
}

export default ExpandableOrderCard;